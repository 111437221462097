import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="spells-dancing-lights-to-eyebite"></a><h2>Spells</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Dancing Lights - Eyebite</span>
    </h2>
    <h6><a id="dancing-lights">Dancing Lights</a></h6>
    <p className="initial"><i>Evocation [Light]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 0, Sor/Wiz 0</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Up to four
lights, all
within a 10- ft.-radius area</span> <span className="stat-block"><b>Duration</b>:
1 minute (D)</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>Depending on the version selected, you create up to four
lights that resemble lanterns or torches (and cast that amount of
light), or up to four glowing spheres of light (which look like
will-o&rsquo;-wisps), or one faintly glowing, vaguely humanoid shape.
The
dancing lights must stay within a 10-foot-radius area in relation to
each other but otherwise move as you desire (no concentration
required): forward or back, up or down, straight or turning corners, or
the like. The lights can move up to 100 feet per round. A light winks
out if the distance between you and it exceeds the spell&rsquo;s range.</p>
    <p>Dancing lights can be made permanent with a permanency spell.</p>
    <h6><a id="darkness">Darkness</a></h6>
    <p className="initial"><i>Evocation [Darkness]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Clr 2, Sor/Wiz 2</span>
    <span className="stat-block"><b>Components</b>: V, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Object touched</span> <span className="stat-block"><b>Duration</b>: 10
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell causes an object to radiate shadowy illumination
out to a 20-foot radius. All creatures in the area gain concealment
(20% miss chance). Even creatures that can normally see in such
conditions (such as with darkvision or low-light vision) have the miss
chance in an area shrouded in magical darkness.</p>
    <p>Normal lights (torches, candles, lanterns, and so forth) are
incapable of brightening the area, as are light spells of lower level.
Higher level light spells are not affected by darkness.</p>
    <p>If darkness is cast on a small object that is then placed
inside or under a lightproof covering, the spell&rsquo;s effect is
blocked
until the covering is removed.</p>
    <p>Darkness counters or dispels any light spell of equal or lower
spell level.</p>
    <p><i>Arcane Material Component</i>: A bit of bat fur and either
a drop of pitch or a piece of coal.</p>
    <h6><a id="darkvision">Darkvision</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Rgr 3, Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>: 1
hour/level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(harmless)</span><span className="stat-block"></span><span className="stat-block"><b>Spell Resistance</b>: Yes (harmless)</span>
    <p>The subject gains the ability to see 60 feet even in total
darkness. Darkvision is black and white only but otherwise like normal
sight. Darkvision does not grant one the ability to see in magical
darkness.</p>
    <p>Darkvision can be made permanent with a permanency spell.</p>
    <p><i>Material Component</i>: Either a pinch of dried carrot or
an agate.</p>
    <h6><a id="daylight">Daylight</a></h6>
    <p className="initial"><i>Evocation [Light]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Clr 3, Drd 3, Pal
3, Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>: Object touched</span> <span className="stat-block"><b>Duration</b>: 10 min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>The object touched sheds light as bright as full daylight in a
60-foot radius, and dim light for an additional 60 feet beyond that.
Creatures that take penalties in bright light also take them while
within the radius of this magical light. Despite its name, this spell
is not the equivalent of daylight for the purposes of creatures that
are damaged or destroyed by bright light.</p>
    <p>If daylight is cast on a small object that is then placed
inside or under a light- proof covering, the spell&rsquo;s effects are
blocked until the covering is removed.</p>
    <p>Daylight brought into an area of magical darkness (or vice
versa) is temporarily negated, so that the otherwise prevailing light
conditions exist in the overlapping areas of effect.</p>
    <p>Daylight counters or dispels any darkness spell of equal or
lower level, such as darkness.</p>
    <h6><a id="daze">Daze</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 0, Sor/Wiz 0</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One humanoid
creature of
4 HD or less</span> <span className="stat-block"><b>Duration</b>: 1 round</span>
    <span className="stat-block"><b>Saving Throw</b>: Will negates</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This enchantment clouds the mind of a humanoid creature with 4
or fewer Hit Dice so that it takes no actions. Humanoids of 5 or more
HD are not affected. A <a href="abilitiesAndConditions.html#dazed" style={{
        "color": "rgb(87, 158, 182)"
      }}>dazed</a> subject is not stunned, so
attackers get
no special advantage against it.</p>
    <p><i>Material Component</i>: A pinch of wool or similar
substance.</p>
    <h6><a id="daze-monster">Daze Monster</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Sor/Wiz 2</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Target</b>: One living
creature of 6
HD or less</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#daze">daze</a>, but daze monster can affect
any one living creature of any type. Creatures of 7 or more HD are not
affected.</p>
    <h6><a id="death-knell">Death Knell</a></h6>
    <p className="initial"><i>Necromancy [Death, Evil]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 2, Death 2</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Living creature touched</span> <span className="stat-block"><b>Duration</b>:
Instantaneous/10
minutes per HD of subject; see text</span> <span className="stat-block"><b>Saving
Throw</b>: Will negates</span> <span className="stat-block"><b>Spell
Resistance</b>: Yes</span>
    <p>You draw forth the ebbing life force of a creature and use it
to fuel your own power. Upon casting this spell, you touch a living
creature that has &ndash;1 or fewer hit points. If the subject fails
its
saving throw, it dies, and you gain 1d8 temporary hit points and a +2
bonus to Strength. Additionally, your effective caster level goes up by
+1, improving spell effects dependent on caster level. (This increase
in effective caster level does not grant you access to more spells.)
These effects last for 10 minutes per HD of the subject creature.</p>
    <h6><a id="death-ward">Death Ward</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 4, Death 4, Drd 5, Pal
4</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Living creature touched</span> <span className="stat-block"><b>Duration</b>:
1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>The subject is immune to all death spells, magical death
effects, energy drain, and any negative energy effects.</p>
    <p>This spell doesn&rsquo;t remove negative levels that the
subject has
already gained, nor does it affect the saving throw necessary 24 hours
after gaining a negative level.</p>
    <p>Death ward does not protect against other sorts of attacks
even if those attacks might be lethal.</p>
    <h6><a id="deathwatch">Deathwatch</a></h6>
    <p className="initial"><i>Necromancy [Evil]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 30 ft.</span> <span className="stat-block"><b>Area</b>: Cone-shaped emanation</span> <span className="stat-block"><b>Duration</b>: 10 min./level</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>Using the foul sight granted by the powers of unlife, you can
determine the condition of creatures near death within the
spell&rsquo;s
range. You instantly know whether each creature within the area is
dead, fragile (alive and wounded, with 3 or fewer hit points left),
fighting off death (alive with 4 or more hit points), undead, or
neither alive nor dead (such as a construct).</p>
    <p>Deathwatch sees through any spell or ability that allows
creatures to feign death.</p>
    <h6><a id="deep-slumber"></a>Deep Slumber</h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Sor/Wiz 3</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="spellsS.html#sleep">sleep</a>, except that it affects 10 HD
of creatures.</p>
    <h6><a id="deeper-darkness"></a>Deeper Darkness</h6>
    <p className="initial"><i>Evocation [Darkness]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3</span> <span className="stat-block"><b>Duration</b>: One day/level (D)</span>
    <p>This spell functions like <a style={{
        "color": "#579eb5"
      }} href="#darkness">darkness</a>, except that the object
radiates shadowy illumination in a 60-foot radius and the darkness
lasts longer.</p>
    <p>Daylight brought into an area of deeper darkness (or vice
versa) is temporarily negated, so that the otherwise prevailing light
conditions exist in the overlapping areas of effect.</p>
    <p>Deeper darkness counters and dispels any light spell of equal
or lower level, including daylight and light.</p>
    <h6><a id="delay-poison">Delay Poison</a></h6>
    <p className="initial"><i>Conjuration (Healing)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Clr 2, Drd 2, Pal
2, Rgr 1</span> <span className="stat-block"><b>Components</b>: V, S, DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>: Creature touched</span> <span className="stat-block"><b>Duration</b>: 1 hour/level</span> <span className="stat-block"><b>Saving Throw</b>: Fortitude negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>The subject becomes temporarily immune to poison. Any poison
in its system or any poison to which it is exposed during the
spell&rsquo;s
duration does not affect the subject until the spell&rsquo;s duration
has
expired. Delay poison does not cure any damage that poison may have
already done.</p>
    <h6><a id="delayed-blast-fireball">Delayed Blast Fireball</a></h6>
    <p className="initial"><i>Evocation [Fire]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 7</span> <span className="stat-block"><b>Duration</b>: 5 rounds or less; see
text</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="spellsFtoG.html#fireball">fireball</a>, except that it is more
powerful and can detonate up to 5 rounds after the spell is cast. The
burst of flame deals 1d6 points of fire damage per caster level
(maximum 20d6).</p>
    <p>The glowing bead created by delayed blast fireball can
detonate immediately if you desire, or you can choose to delay the
burst for as many as 5 rounds. You select the amount of delay upon
completing the spell, and that time cannot change once it has been set
unless someone touches the bead (see below). If you choose a delay, the
glowing bead sits at its destination until it detonates. A creature can
pick up and hurl the bead as a thrown weapon (range increment 10 feet).
If a creature handles and moves the bead within 1 round of its
detonation, there is a 25% chance that the bead detonates while being
handled.</p>
    <h6><a id="demand">Demand</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 8</span> <span className="stat-block"><b>Saving Throw</b>: Will partial</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="spellsS.html#sending">sending</a>, but the message can also
contain a suggestion (see the <a style={{
        "color": "#579eb6"
      }} href="spellsS.html#suggestion">suggestion</a> spell), which the subject does
its best to carry out. A successful Will save negates the suggestion
effect but not the contact itself. The demand, if received, is
understood even if the subject&rsquo;s Intelligence score is as low as
1. If
the message is impossible or meaningless according to the circumstances
that exist for the subject at the time the demand is issued, the
message is understood but the suggestion is ineffective.</p>
    <p>The demand&rsquo;s message to the creature must be twenty-five
words
or less, including the suggestion. The creature can also give a short
reply immediately.</p>
    <p><i>Material Component</i>: A short piece of copper wire and
some small part of the subject&mdash;a hair, a bit of nail, or the like.</p>
    <h6><a id="desecrate">Desecrate</a></h6>
    <p className="initial"><i>Evocation [Evil]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 2, Evil 2</span> <span className="stat-block"><b>Components</b>: V, S, M, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Area</b>: 20-ft.-radius
emanation</span> <span className="stat-block"><b>Duration</b>: 2
hours/level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell imbues an area with negative energy. Each Charisma
check made to <a href="specialAttacks.html#turn" style={{
        "color": "rgb(87, 158, 182)"
      }}>turn undead</a> within this area
takes a &ndash;3 profane penalty,
and every undead creature entering a desecrated area gains a +1 profane
bonus on attack rolls, damage rolls, and saving throws. An undead
creature created within or summoned into such an area gains +1 hit
points per HD.</p>
    <p>If the desecrated area contains an altar, shrine, or other
permanent fixture dedicated to your deity or aligned higher power, the
modifiers given above are doubled (&ndash;6 profane penalty on turning
checks, +2 profane bonus and +2 hit points per HD for undead in the
area).</p>
    <p>Furthermore, anyone who casts animate dead within this area
may create as many as double the normal amount of undead (that is, 4 HD
per caster level rather than 2 HD per caster level).</p>
    <p>If the area contains an altar, shrine, or other permanent
fixture of a deity, pantheon, or higher power other than your patron,
the desecrate spell instead curses the area, cutting off its connection
with the associated deity or power. This secondary function, if used,
does not also grant the bonuses and penalties relating to undead, as
given above.</p>
    <p>Desecrate counters and dispels consecrate.</p>
    <p><i>Material Component</i>: A vial of unholy water and 25 gp
worth (5 pounds) of silver dust, all of which must be sprinkled around
the area.</p>
    <h6><a id="destruction">Destruction</a></h6>
    <p className="initial"><i>Necromancy [Death]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 7, Death 7</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One creature</span>
    <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Fortitude partial</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell instantly slays the subject and consumes its
remains (but not its equipment and possessions) utterly. If the
target&rsquo;s Fortitude saving throw succeeds, it instead takes 10d6
points
of damage. The only way to restore life to a character who has failed
to save against this spell is to use true resurrection, a carefully
worded wish spell followed by resurrection, or miracle.</p>
    <p><i>Focus</i>: A special holy (or unholy) symbol of silver
marked with verses of anathema (cost 500 gp).</p>
    <h6><a id="detect-animals-or-plants">Detect Animals or Plants</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Drd 1, Rgr 1</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Area</b>: Cone-shaped
emanation</span> <span className="stat-block"><b>Duration</b>:
Concentration, up to 10
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You can detect a particular kind of animal or plant in a cone
emanating out from you in whatever direction you face. You must think
of a kind of animal or plant when using the spell, but you can change
the animal or plant kind each round. The amount of information revealed
depends on how long you search a particular area or focus on a specific
kind of animal or plant.</p>
    <p><i>1st Round</i>: Presence or absence of that kind of animal
or plant in the area.</p>
    <p><i>2nd Round</i>: Number of individuals of the specified kind
in the area, and the condition of the healthiest specimen.</p>
    <p><i>3rd Round</i>: The condition (see below) and location of
each individual present. If an animal or plant is outside your line of
sight, then you discern its direction but not its exact location.</p>
    <p><i>Conditions</i>: For purposes of this spell, the categories
of condition are as follows:</p>
    <p><i>Normal</i>: Has at least 90% of full normal hit points,
free of disease.</p>
    <p><i>Fair</i>: 30% to 90% of full normal hit points remaining.</p>
    <p><i>Poor</i>: Less than 30% of full normal hit points
remaining, afflicted with a disease, or suffering from a debilitating
injury.</p>
    <p><i>Weak</i>: 0 or fewer hit points remaining, afflicted with a
disease in the terminal stage, or crippled.</p>
    <p>If a creature falls into more than one category, the spell
indicates the weaker of the two.</p>
    <p>Each round you can turn to detect a kind of animal or plant in
a new area. The spell can penetrate barriers, but 1 foot of stone, 1
inch of common metal, a thin sheet of lead, or 3 feet of wood or dirt
blocks it.</p>
    <h6><a id="detect-chaos">Detect Chaos</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#detect-evil">detect evil</a>, except that it detects
the auras of chaotic creatures, clerics of chaotic deities, chaotic
spells, and chaotic magic items, and you are vulnerable to an
overwhelming chaotic aura if you are lawful.</p>
    <h6><a id="detect-evil">Detect Evil</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 60 ft.</span> <span className="stat-block"><b>Area</b>: Cone-shaped emanation</span> <span className="stat-block"><b>Duration</b>: Concentration, up to 10
min./ level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You can sense the presence of evil. The amount of information
revealed depends on how long you study a particular area or subject.</p>
    <p><i>1st Round</i>: Presence or absence of evil.</p>
    <p><i>2nd Round</i>: Number of evil auras (creatures, objects, or
spells) in the area and the power of the most potent evil aura present.</p>
    <p>If you are of good alignment, and the strongest evil
aura&rsquo;s
power is overwhelming (see below), and the HD or level of the
aura&rsquo;s
source is at least twice your character level, you are <a href="abilitiesAndConditions.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stunned</a> for 1
round and the spell ends.</p>
    <p><i>3rd Round</i>: The power and location of each aura. If an
aura is outside your line of sight, then you discern its direction but
not its exact location.</p>
    <p><i>Aura Power</i>: An evil aura&rsquo;s power depends on the
type of
evil creature or object that you&rsquo;re detecting and its HD, caster
level,
or (in the case of a cleric) class level; see the accompanying table.
If an aura falls into more than one strength category, the spell
indicates the stronger of the two.</p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th>
          </th>
          <th colSpan="4" className="center">&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;
Aura Power &mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;</th>
        </tr>
        <tr>
          <th>Creature/Object</th>
          <th>Faint</th>
          <th>Moderate</th>
          <th>Strong</th>
          <th>Overwhelming</th>
        </tr>
        <tr className="odd-row">
          <td>Evil creature1 (HD)</td>
          <td>10 or lower</td>
          <td>11&ndash;25</td>
          <td>26&ndash;50</td>
          <td>51 or higher</td>
        </tr>
        <tr>
          <td>Undead (HD)</td>
          <td>2 or lower</td>
          <td>3&ndash;8</td>
          <td>9&ndash;20</td>
          <td>21 or higher</td>
        </tr>
        <tr className="odd-row">
          <td>Evil outsider (HD)</td>
          <td>1 or lower</td>
          <td>2&ndash;4</td>
          <td>5&ndash;10</td>
          <td>11 or higher</td>
        </tr>
        <tr>
          <td>Cleric of an evil deity 2 (class levels)</td>
          <td>1</td>
          <td>2&ndash;4</td>
          <td>5&ndash;10</td>
          <td>11 or higher</td>
        </tr>
        <tr className="odd-row">
          <td>Evil magic item or spell (caster level)</td>
          <td>2nd or lower</td>
          <td>3rd&ndash;8th</td>
          <td>9th&ndash;20th</td>
          <td>21st or higher</td>
        </tr>
        <tr>
          <td colSpan="5">1 <i>Except for undead and outsiders,
which have their own entries on the table.</i></td>
        </tr>
        <tr>
          <td colSpan="5">2 <i>Some characters who are not clerics
may radiate an aura of equivalent power. The class description will
indicate whether this applies.</i></td>
        </tr>
      </tbody>
    </table>
    <p><i>Lingering Aura</i>: An evil aura lingers after its original
source dissipates (in the case of a spell) or is destroyed (in the case
of a creature or magic item). If detect evil is cast and directed at
such a location, the spell indicates an aura strength of dim (even
weaker than a faint aura). How long the aura lingers at this dim level
depends on its original power:</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "150px"
          }}>Original Strength</th>
          <th>Duration of Lingering Aura &nbsp;</th>
        </tr>
        <tr className="odd-row">
          <td>Faint</td>
          <td>1d6 rounds</td>
        </tr>
        <tr>
          <td>Moderate</td>
          <td>1d6 minutes</td>
        </tr>
        <tr className="odd-row">
          <td>Strong</td>
          <td>1d6x10 minutes</td>
        </tr>
        <tr>
          <td className="last-row">Overwhelming</td>
          <td className="last-row">1d6 days</td>
        </tr>
      </tbody>
    </table>
    <p>Animals, traps, poisons, and other potential perils are not
evil, and as such this spell does not detect them.</p>
    <p>Each round, you can turn to detect evil in a new area. The
spell can penetrate barriers, but 1 foot of stone, 1 inch of common
metal, a thin sheet of lead, or 3 feet of wood or dirt blocks it.</p>
    <h6><a id="detect-good">Detect Good</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#detect-evil">detect evil</a>, except that it detects
the auras of good creatures, clerics or paladins of good deities, good
spells, and good magic items, and you are vulnerable to an overwhelming
good aura if you are evil. Healing potions, antidotes, and similar
beneficial items are not good.</p>
    <h6><a id="detect-law">Detect Law</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#detect-evil">detect evil</a>, except that it detects
the auras of lawful creatures, clerics of lawful deities, lawful
spells, and lawful magic items, and you are vulnerable to an
overwhelming lawful aura if you are chaotic.</p>
    <h6><a id="detect-magic">Detect Magic</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Brd 0, Clr 0, Drd 0,
Sor/Wiz 0</span> <span className="stat-block"><b>Components</b>: V, S</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: 60 ft.</span> <span className="stat-block"><b>Area</b>: Cone-shaped emanation</span> <span className="stat-block"><b>Duration</b>: Concentration, up to 1
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You detect magical auras. The amount of information revealed
depends on how long you study a particular area or subject.</p>
    <p><i>1st Round</i>: Presence or absence of magical auras.</p>
    <p><i>2nd Round</i>: Number of different magical auras and the
power of the most potent aura.</p>
    <p><i>3rd Round</i>: The strength and location of each aura. If
the items or creatures bearing the auras are in line of sight, you can
make <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a> skill checks to
determine the school of magic involved
in each. (Make one check per aura; DC 15 + spell level, or 15 + half
caster level for a nonspell effect.)</p>
    <p>Magical areas, multiple types of magic, or strong local
magical emanations may distort or conceal weaker auras.</p>
    <p><i>Aura Strength</i>: An aura&rsquo;s power depends on a
spell&rsquo;s
functioning spell level or an item&rsquo;s caster level. If an aura
falls
into more than one category, detect magic indicates the stronger of the
two.</p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th>
          </th>
          <th colSpan="4" className="center">&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;
Aura Power
&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;</th>
        </tr>
        <tr>
          <th>Spell or Object</th>
          <th>Faint</th>
          <th>Moderate</th>
          <th>Strong</th>
          <th>Overwhelming</th>
        </tr>
        <tr className="odd-row">
          <td>Functioning spell (spell level)</td>
          <td>3rd or lower</td>
          <td>4th&ndash;6th</td>
          <td>7th&ndash;9th</td>
          <td>10th+ (deity-level)</td>
        </tr>
        <tr>
          <td className="last-row">Magic item (caster level)</td>
          <td className="last-row">5th or lower</td>
          <td className="last-row">6th&ndash;11th</td>
          <td className="last-row">12th&ndash;20th</td>
          <td className="last-row">21st+ (artifact)</td>
        </tr>
      </tbody>
    </table>
    <p><i>Lingering Aura</i>: A magical aura lingers after its
original source dissipates (in the case of a spell) or is destroyed (in
the case of a magic item). If detect magic is cast and directed at such
a location, the spell indicates an aura strength of dim (even weaker
than a faint aura). How long the aura lingers at this dim level depends
on its original power:</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "150px"
          }}>Original Strength</th>
          <th>Duration of Lingering Aura &nbsp;</th>
        </tr>
        <tr className="odd-row">
          <td>Faint</td>
          <td>1d6 rounds</td>
        </tr>
        <tr>
          <td>Moderate</td>
          <td>1d6 minutes</td>
        </tr>
        <tr className="odd-row">
          <td>Strong</td>
          <td>1d6x10 minutes</td>
        </tr>
        <tr>
          <td className="last-row">Overwhelming</td>
          <td className="last-row">1d6 days</td>
        </tr>
      </tbody>
    </table>
    <p>Outsiders and elementals are not magical in themselves, but if
they are summoned, the conjuration spell registers.</p>
    <p>Each round, you can turn to detect magic in a new area. The
spell can penetrate barriers, but 1 foot of stone, 1 inch of common
metal, a thin sheet of lead, or 3 feet of wood or dirt blocks it.</p>
    <p>Detect magic can be made permanent with a permanency spell.</p>
    <h6><a id="detect-poison">Detect Poison</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Clr 0, Drd 0, Pal 1, Rgr
1, Sor/Wiz 0</span> <span className="stat-block"><b>Components</b>: V, S</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span style={{
      "fontWeight": "bold"
    }}>Target or Area:</span>
One creature, one object, or a 5-ft.
cube 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No
    <p>You determine whether a creature, object, or area has been
poisoned or is poisonous. You can determine the exact type of poison
with a DC 20 Wisdom check. A character with the <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> (alchemy) skill
may try a DC 20 Craft (alchemy) check if the Wisdom check fails, or may
try the Craft (alchemy) check prior to the Wisdom check.</p>
    <p>The spell can penetrate barriers, but 1 foot of stone, 1 inch
of common metal, a thin sheet of lead, or 3 feet of wood or dirt blocks
it.</p>
    <h6><a id="detect-scrying">Detect Scrying</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Brd 4, Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 40 ft.</span> <span className="stat-block"><b>Area</b>: 40-ft.-radius emanation
centered on you</span> <span className="stat-block"><b>Duration</b>: 24
hours</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You immediately become aware of any attempt to observe you by
means of a divination (scrying) spell or effect. The spell&rsquo;s area
radiates from you and moves as you move. You know the location of every
magical sensor within the spell&rsquo;s area.</p>
    <p>If the scrying attempt originates within the area, you also
know its location; otherwise, you and the scrier immediately make
opposed caster level checks (1d20 + caster level). If you at least
match the scrier&rsquo;s result, you get a visual image of the scrier
and an
accurate sense of his or her direction and distance from you.</p>
    <p><i>Material Component</i>: A small piece of mirror and a
miniature brass hearing trumpet.</p>
    <h6><a id="detect-secret-doors">Detect Secret Doors</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Knowledge 1,
Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, S</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: 60 ft.</span> <span className="stat-block"><b>Area</b>: Cone-shaped emanation</span> <span className="stat-block"><b>Duration</b>: Concentration, up to 1
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You can detect secret doors, compartments, caches, and so
forth. Only passages, doors, or openings that have been specifically
constructed to escape detection are detected by this spell. The amount
of information revealed depends on how long you study a particular area
or subject.</p>
    <p><i>1st Round</i>: Presence or absence of secret doors.</p>
    <p><i>2nd Round</i>: Number of secret doors and the location of
each. If an aura is outside your line of sight, then you discern its
direction but not its exact location.</p>
    <p><i>Each Additional Round</i>: The mechanism or trigger for one
particular secret portal closely examined by you. Each round, you can
turn to detect secret doors in a new area. The spell can penetrate
barriers, but 1 foot of stone, 1 inch of common metal, a thin sheet of
lead, or 3 feet of wood or dirt blocks it.</p>
    <h6><a id="detect-snares-and-pits">Detect Snares and Pits</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Drd 1, Rgr 1</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 60 ft.</span> <span className="stat-block"><b>Area</b>: Cone-shaped emanation</span> <span className="stat-block"><b>Duration</b>: Concentration, up to 10
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You can detect simple pits, deadfalls, and snares as well as
mechanical traps constructed of natural materials. The spell does not
detect complex traps, including trapdoor traps.</p>
    <p>Detect snares and pits does detect certain natural
hazards&mdash;quicksand (a snare), a sinkhole (a pit), or unsafe walls
of
natural rock (a deadfall). However, it does not reveal other
potentially dangerous conditions. The spell does not detect magic traps
(except those that operate by pit, deadfall, or snaring; see the spell
snare), nor mechanically complex ones, nor those that have been
rendered safe or inactive.</p>
    <p>The amount of information revealed depends on how long you
study a particular area.</p>
    <p><i>1st Round</i>: Presence or absence of hazards.</p>
    <p><i>2nd Round</i>: Number of hazards and the location of each.
If a hazard is outside your line of sight, then you discern its
direction but not its exact location.</p>
    <p><i>Each Additional Round</i>: The general type and trigger for
one particular hazard closely examined by you.</p>
    <p>Each round, you can turn to detect snares and pits in a new
area. The spell can penetrate barriers, but 1 foot of stone, 1 inch of
common metal, a thin sheet of lead, or 3 feet of wood or dirt blocks it.</p>
    <h6><a id="detect-thoughts">Detect Thoughts</a></h6>
    <p className="initial"><i>Divination [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Knowledge 2,
Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, F/DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: 60 ft.</span> <span className="stat-block"><b>Area</b>: Cone-shaped emanation</span> <span className="stat-block"><b>Duration</b>: Concentration, up to 1
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You detect surface thoughts. The amount of information
revealed depends on how long you study a particular area or subject.</p>
    <p><i>1st Round</i>: Presence or absence of thoughts (from
conscious creatures with Intelligence scores of 1 or higher).</p>
    <p><i>2nd Round</i>: Number of thinking minds and the
Intelligence score of each. If the highest Intelligence is 26 or higher
(and at least 10 points higher than your own Intelligence score), you
are <a href="abilitiesAndConditions.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stunned</a> for 1 round and the
spell ends. This spell does not let you
determine the location of the thinking minds if you can&rsquo;t see the
creatures whose thoughts you are detecting.</p>
    <p><i>3rd Round</i>: Surface thoughts of any mind in the area. A
target&rsquo;s Will save prevents you from reading its thoughts, and
you must
cast detect thoughts again to have another chance. Creatures of animal
intelligence (Int 1 or 2) have simple, instinctual thoughts that you
can pick up.</p>
    <p>Each round, you can turn to detect thoughts in a new area. The
spell can penetrate barriers, but 1 foot of stone, 1 inch of common
metal, a thin sheet of lead, or 3 feet of wood or dirt blocks it.</p>
    <p><i>Arcane Focus</i>: A copper piece.</p>
    <h6><a id="detect-undead">Detect Undead</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1, Pal 1, Sor/Wiz 1</span>
    <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 60 ft.</span> <span className="stat-block"><b>Area</b>: Cone-shaped emanation</span> <span className="stat-block"><b>Duration</b>: Concentration, up to 1
minute/ level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You can detect the aura that surrounds undead creatures. The
amount of information revealed depends on how long you study a
particular area.</p>
    <p><i>1st Round</i>: Presence or absence of undead auras.</p>
    <p><i>2nd Round</i>: Number of undead auras in the area and the
strength of the strongest undead aura present. If you are of good
alignment, and the strongest undead aura&rsquo;s strength is
overwhelming
(see below), and the creature has HD of at least twice your character
level, you are <a href="abilitiesAndConditions.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stunned</a> for 1 round and the
spell ends.</p>
    <p><i>3rd Round</i>: The strength and location of each undead
aura. If an aura is outside your line of sight, then you discern its
direction but not its exact location.</p>
    <p><i>Aura Strength</i>: The strength of an undead aura is
determined by the HD of the undead creature, as given on the following
table:</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>HD</th>
          <th style={{
            "width": "100px"
          }}>Strength</th>
        </tr>
        <tr className="odd-row">
          <td>1 or lower</td>
          <td>Faint</td>
        </tr>
        <tr>
          <td>2&ndash;4</td>
          <td>Moderate</td>
        </tr>
        <tr className="odd-row">
          <td>5&ndash;10</td>
          <td>Strong</td>
        </tr>
        <tr>
          <td className="last-row">11 or higher</td>
          <td className="last-row">Overwhelming</td>
        </tr>
      </tbody>
    </table>
    <p><i>Lingering Aura</i>: An undead aura lingers after its
original source is destroyed. If detect undead is cast and directed at
such a location, the spell indicates an aura strength of dim (even
weaker than a faint aura). How long the aura lingers at this dim level
depends on its original power:</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "150px"
          }}>Original Strength</th>
          <th>Duration of Lingering Aura</th>
        </tr>
        <tr className="odd-row">
          <td>Faint</td>
          <td>1d6 rounds</td>
        </tr>
        <tr>
          <td>Moderate</td>
          <td>1d6 minutes</td>
        </tr>
        <tr className="odd-row">
          <td>Strong</td>
          <td>1d6x10 minutes</td>
        </tr>
        <tr>
          <td className="last-row">Overwhelming</td>
          <td className="last-row">1d6 days</td>
        </tr>
      </tbody>
    </table>
    <p>Each round, you can turn to detect undead in a new area. The
spell can penetrate barriers, but 1 foot of stone, 1 inch of common
metal, a thin sheet of lead, or 3 feet of wood or dirt blocks it.</p>
    <p><i>Arcane Material Component</i>: A bit of earth from a grave.</p>
    <h6><a id="dictum">Dictum</a></h6>
    <p className="initial"><i>Evocation [Lawful, Sonic]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 7, Law 7</span> <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 40 ft.</span> <span className="stat-block"><b>Area</b>: Nonlawful creatures in a
40-ft.-radius spread centered on you</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
None or Will
negates; see text</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>Any nonlawful creature within the area of a dictum spell
suffers the following ill effects.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "150px"
          }}>HD</th>
          <th>Effect</th>
        </tr>
        <tr className="odd-row">
          <td>Equal to caster level</td>
          <td><a href="abilitiesAndConditions.html#deafened" style={{
              "color": "rgb(87, 158, 182)"
            }}>Deafened</a></td>
        </tr>
        <tr>
          <td>Up to caster level &ndash;1</td>
          <td>Slowed, deafened</td>
        </tr>
        <tr className="odd-row">
          <td>Up to caster level &ndash;5</td>
          <td><a href="abilitiesAndConditions.html#paralyzed" style={{
              "color": "rgb(87, 158, 182)"
            }}>Paralyzed</a>, slowed, deafened</td>
        </tr>
        <tr>
          <td className="last-row">Up to caster level &ndash;10</td>
          <td className="last-row">Killed, paralyzed, slowed, deafened</td>
        </tr>
      </tbody>
    </table>
    <p>The effects are cumulative and concurrent. No saving throw is
allowed against these effects.</p>
    <p><i>Deafened</i>: The creature is deafened for 1d4 rounds.</p>
    <p><i>Slowed</i>: The creature is slowed, as by the slow spell,
for 2d4 rounds.</p>
    <p><i>Paralyzed</i>: The creature is paralyzed and <a href="abilitiesAndConditions.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a> for
1d10 minutes.</p>
    <p><i>Killed</i>: Living creatures die. Undead creatures are
destroyed.</p>
    <p>Furthermore, if you are on your home plane when you cast this
spell, nonlawful extraplanar creatures within the area are instantly
banished back to their home planes. Creatures so banished cannot return
for at least 24 hours. This effect takes place regardless of whether
the creatures hear the dictum. The banishment effect allows a Will save
(at a &ndash;4 penalty) to negate.</p>
    <p>Creatures whose HD exceed your caster level are unaffected by
dictum.</p>
    <h6><a id="dimension-door">Dimension Door</a></h6>
    <p className="initial"><i>Conjuration (Teleportation)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 4, Sor/Wiz 4, Travel 4</span>
    <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Target</b>: You and
touched objects
or other touched willing creatures</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
None and Will
negates (object)</span> <span className="stat-block"><b>Spell Resistance</b>:
No and Yes
(object)</span>
    <p>You instantly transfer yourself from your current location to
any other spot within range. You always arrive at exactly the spot
desired&mdash;whether by simply visualizing the area or by stating
direction.
After using this spell, you can&rsquo;t take any other actions until
your
next turn. You can bring along objects as long as their weight
doesn&rsquo;t
exceed your maximum load. You may also bring one additional willing
Medium or smaller creature (carrying gear or objects up to its maximum
load) or its equivalent per three caster levels. A Large creature
counts as two Medium creatures, a Huge creature counts as two Large
creatures, and so forth. All creatures to be transported must be in
contact with one another, and at least one of those creatures must be
in contact with you.</p>
    <p>If you arrive in a place that is already occupied by a solid
body, you and each creature traveling with you take 1d6 points of
damage and are shunted to a random open space on a suitable surface
within 100 feet of the intended location.</p>
    <p>If there is no free space within 100 feet, you and each
creature traveling with you take an additional 2d6 points of damage and
are shunted to a free space within 1,000 feet. If there is no free
space within 1,000 feet, you and each creature travelling with you take
an additional 4d6 points of damage and the spell simply fails.</p>
    <h6><a id="dimensional-anchor">Dimensional Anchor</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Clr 4, Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Ray</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes (object)</span>
    <p>A green ray springs from your outstretched hand. You must make
a ranged touch attack to hit the target. Any creature or object struck
by the ray is covered with a shimmering emerald field that completely
blocks extradimensional travel. Forms of movement barred by a
dimensional anchor include astral projection, blink, dimension door,
ethereal jaunt, etherealness, gate, maze, plane shift, shadow walk,
teleport, and similar spell-like or psionic abilities. The spell also
prevents the use of a gate or teleportation circle for the duration of
the spell.</p>
    <p>A dimensional anchor does not interfere with the movement of
creatures already in ethereal or astral form when the spell is cast,
nor does it block extradimensional perception or attack forms. Also,
dimensional anchor does not prevent summoned creatures from
disappearing at the end of a summoning spell.</p>
    <h6><a id="dimensional-lock">Dimensional Lock</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Clr 8, Sor/Wiz 8</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Area</b>: 20-ft.-radius
emanation
centered on a point in space</span> <span className="stat-block"><b>Duration</b>:
One day/level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You create a shimmering emerald barrier that completely blocks
extradimensional travel. Forms of movement barred include astral
projection, blink, dimension door, ethereal jaunt, etherealness, gate,
maze, plane shift, shadow walk, teleport, and similar spell-like or
psionic abilities. Once dimensional lock is in place, extradimensional
travel into or out of the area is not possible.</p>
    <p>A dimensional lock does not interfere with the movement of
creatures already in ethereal or astral form when the spell is cast,
nor does it block extradimensional perception or attack forms. Also,
the spell does not prevent summoned creatures from disappearing at the
end of a summoning spell.</p>
    <h6><a id="diminish-plants">Diminish Plants</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 3, Rgr 3</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: See text</span> <span style={{
      "fontWeight": "bold"
    }}>Target or Area:</span> See text 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No
    <p>This spell has two versions.</p>
    <p><i>Prune Growth</i>: This version causes normal vegetation
within long range (400 feet + 40 feet per level) to shrink to about
one-third of their normal size, becoming untangled and less bushy. The
affected vegetation appears to have been carefully pruned and trimmed.</p>
    <p>At your option, the area can be a 100- foot-radius circle, a
150-foot-radius semicircle, or a 200-foot-radius quarter-circle.</p>
    <p>You may also designate portions of the area that are not
affected.</p>
    <p><i>Stunt Growth</i>: This version targets normal plants within
a range of 1/2 mile, reducing their potential productivity over the
course of the following year to one third below normal.</p>
    <p>Diminish plants counters plant growth.</p>
    <p>This spell has no effect on plant creatures.</p>
    <h6><a id="discern-lies">Discern Lies</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Clr 4, Pal 3</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: One
creature/level, no
two of which can be more than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: Concentration, up to 1
round/level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>Each round, you concentrate on one subject, who must be within
range. You know if the subject deliberately and knowingly speaks a lie
by discerning disturbances in its aura caused by lying. The spell does
not reveal the truth, uncover unintentional inaccuracies, or
necessarily reveal evasions.</p>
    <p>Each round, you may concentrate on a different subject.</p>
    <h6><a id="discern-location">Discern Location</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Clr 8, Knowledge 8,
Sor/Wiz 8</span> <span className="stat-block"><b>Components</b>: V, S, DF</span>
    <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Unlimited</span> <span className="stat-block"><b>Target</b>: One creature or object</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>A discern location spell is among the most powerful means of
locating creatures or objects. Nothing short of a mind blank spell or
the direct intervention of a deity keeps you from learning the exact
location of a single individual or object. Discern location circumvents
normal means of protection from scrying or location. The spell reveals
the name of the creature or object&rsquo;s location (place, name,
business
name, building name, or the like), community, county (or similar
political division), country, continent, and the plane of existence
where the target lies.</p>
    <p>To find a creature with the spell, you must have seen the
creature or have some item that once belonged to it. To find an object,
you must have touched it at least once.</p>
    <h6><a id="disguise-self">Disguise Self</a></h6>
    <p className="initial"><i>Illusion (Glamer)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Sor/Wiz 1, Trickery
1</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
10 min./level (D)</span>
    <p>You make yourself&mdash;including clothing, armor, weapons,
and
equipment&mdash;look different. You can seem 1 foot shorter or taller,
thin,
fat, or in between. You cannot change your body type. Otherwise, the
extent of the apparent change is up to you. You could add or obscure a
minor feature or look like an entirely different person.</p>
    <p>The spell does not provide the abilities or mannerisms of the
chosen form, nor does it alter the perceived tactile (touch) or audible
(sound) properties of you or your equipment. </p>
    <p>If you use this spell to create a disguise, you get a +10
bonus on the <a href="skillsAll.html#disguise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disguise</a> check.</p>
    <p>A creature that interacts with the glamer gets a Will save to
recognize it as an illusion.</p>
    <h6><a id="disintegrate">Disintegrate</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Destruction 7, Sor/Wiz 6</span>
    <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Ray</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Fortitude partial
(object)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>A thin, green ray springs from your pointing finger. You must
make a successful ranged touch attack to hit. Any creature struck by
the ray takes 2d6 points of damage per caster level (to a maximum of
40d6). Any creature reduced to 0 or fewer hit points by this spell is
entirely disintegrated, leaving behind only a trace of fine dust. A
disintegrated creature&rsquo;s equipment is unaffected.</p>
    <p>When used against an object, the ray simply disintegrates as
much as one 10- foot cube of nonliving matter. Thus, the spell
disintegrates only part of any very large object or structure targeted.
The ray affects even objects constructed entirely of force, such as
forceful hand or a wall of force, but not magical effects such as a
globe of invulnerability or an antimagic field.</p>
    <p>A creature or object that makes a successful Fortitude save is
partially affected, taking only 5d6 points of damage. If this damage
reduces the creature or object to 0 or fewer hit points, it is entirely
disintegrated.</p>
    <p>Only the first creature or object struck can be affected; that
is, the ray affects only one target per casting.</p>
    <p><i>Arcane Material Component</i>: A lodestone and a pinch of
dust.</p>
    <h6><a id="dismissal">Dismissal</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Clr 4, Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One extraplanar
creature</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span>
    <span className="stat-block"><b>Saving Throw</b>: Will negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell forces an extraplanar creature back to its proper
plane if it fails a special Will save (DC = spell&rsquo;s save DC
&ndash;
creature&rsquo;s HD + your caster level). If the spell is successful,
the
creature is instantly whisked away, but there is a 20% chance of
actually sending the subject to a plane other than its own.</p>
    <h6><a id="dispel-chaos">Dispel Chaos</a></h6>
    <p className="initial"><i>Abjuration [Lawful]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 5, Law 5, Pal 4</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#dispel-evil">dispel evil</a>, except that you are
surrounded by constant, blue, lawful energy, and the spell affects
chaotic creatures and spells rather than evil ones.</p>
    <h6><a id="dispel-evil">Dispel Evil</a></h6>
    <p className="initial"><i>Abjuration [Good]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 5, Good 5, Pal 4</span>
    <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span style={{
      "fontWeight": "bold"
    }}>Target or Targets:</span> You and a touched
evil creature from
another plane; or you and an enchantment or evil spell on a touched
creature or object 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
or until discharged, whichever
comes first 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> See text 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> See
text
    <p>Shimmering, white, holy energy surrounds you. This power has
three effects.</p>
    <p>First, you gain a +4 deflection bonus to AC against attacks by
evil creatures.</p>
    <p>Second, on making a successful melee touch attack against an
evil creature from another plane, you can choose to drive that creature
back to its home plane. The creature can negate the effects with a
successful Will save (spell resistance applies). This use discharges
and ends the spell.</p>
    <p>Third, with a touch you can automatically dispel any one
enchantment spell cast by an evil creature or any one evil spell.
Exception: Spells that can&rsquo;t be dispelled by dispel magic also
can&rsquo;t be
dispelled by dispel evil. Saving throws and spell resistance do not
apply to this effect. This use discharges and ends the spell.</p>
    <h6><a id="dispel-good">Dispel Good</a></h6>
    <p className="initial"><i>Abjuration [Evil]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 5, Evil 5</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#dispel-evil">dispel evil</a>, except that you are
surrounded by dark, wavering, unholy energy, and the spell affects good
creatures and spells rather than evil ones.</p>
    <h6><a id="dispel-law">Dispel Law</a></h6>
    <p className="initial"><i>Abjuration [Chaotic]</i></p>
    <span className="stat-block"><b>Level</b>: Chaos 5, Clr 5</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#dispel-evil">dispel evil</a>, except that you are
surrounded by flickering, yellow, chaotic energy, and the spell affects
lawful creatures and spells rather than evil ones.</p>
    <h6><a id="dispel-magic">Dispel Magic</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Clr 3, Drd 4, Magic
3, Pal 3, Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>:
V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard
action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. +
10
ft./level)</span> <span style={{
      "fontWeight": "bold"
    }}>Target or Area:</span>
One spellcaster, creature, or object;
or 20-ft.-radius burst 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No
    <p>You can use dispel magic to end ongoing spells that have been
cast on a creature or object, to temporarily suppress the magical
abilities of a magic item, to end ongoing spells (or at least their
effects) within an area, or to counter another spellcaster&rsquo;s
spell. A
dispelled spell ends as if its duration had expired. Some spells, as
detailed in their descriptions, can&rsquo;t be defeated by dispel
magic.
Dispel magic can dispel (but not counter) spell-like effects just as it
does spells.</p>
    <p><i>Note</i>: The effect of a spell with an instantaneous
duration can&rsquo;t be dispelled, because the magical effect is
already over
before the dispel magic can take effect. </p>
    <p><i>You choose to use dispel magic in one of three ways</i>: a
targeted dispel, an area dispel, or a counterspell:</p>
    <p><i>Targeted Dispel</i>: One object, creature, or spell is the
target of the dispel magic spell. You make a dispel check (1d20 + your
caster level, maximum +10) against the spell or against each ongoing
spell currently in effect on the object or creature. The DC for this
dispel check is 11 + the spell&rsquo;s caster level. If you succeed on
a
particular check, that spell is dispelled; if you fail, that spell
remains in effect.</p>
    <p>If you target an object or creature that is the effect of an
ongoing spell (such as a monster summoned by monster summoning), you
make a dispel check to end the spell that conjured the object or
creature.</p>
    <p>If the object that you target is a magic item, you make a
dispel check against the item&rsquo;s caster level. If you succeed, all
the
item&rsquo;s magical properties are suppressed for 1d4 rounds, after
which
the item recovers on its own. A suppressed item becomes nonmagical for
the duration of the effect. An interdimensional interface (such as a
bag of holding) is temporarily closed. A magic item&rsquo;s physical
properties are unchanged: A suppressed magic sword is still a sword (a
masterwork sword, in fact). Artifacts and deities are unaffected by
mortal magic such as this.</p>
    <p>You automatically succeed on your dispel check against any
spell that you cast yourself.</p>
    <p><i>Area Dispel</i>: When dispel magic is used in this way, the
spell affects everything within a 20-foot radius.</p>
    <p>For each creature within the area that is the subject of one
or more spells, you make a dispel check against the spell with the
highest caster level. If that check fails, you make dispel checks
against progressively weaker spells until you dispel one spell (which
discharges the dispel magic spell so far as that target is concerned)
or until you fail all your checks. The creature&rsquo;s magic items are
not
affected.</p>
    <p>For each object within the area that is the target of one or
more spells, you make dispel checks as with creatures. Magic items are
not affected by an area dispel.</p>
    <p>For each ongoing area or effect spell whose point of origin is
within the area of the dispel magic spell, you can make a dispel check
to dispel the spell.</p>
    <p>For each ongoing spell whose area overlaps that of the dispel
magic spell, you can make a dispel check to end the effect, but only
within the overlapping area.</p>
    <p>If an object or creature that is the effect of an ongoing
spell (such as a monster summoned by monster summoning) is in the area,
you can make a dispel check to end the spell that conjured that object
or creature (returning it whence it came) in addition to attempting to
dispel spells targeting the creature or object.</p>
    <p>You may choose to automatically succeed on dispel checks
against any spell that you have cast.</p>
    <p><i>Counterspell</i>: When dispel magic is used in this way,
the spell targets a spellcaster and is cast as a counterspell. Unlike a
true counterspell, however, dispel magic may not work; you must make a
dispel check to counter the other spellcaster&rsquo;s spell.</p>
    <h6><a id="greater-dispel-magic">Dispel Magic, Greater</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Brd 5, Clr 6, Drd 6,
Sor/Wiz 6</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#dispel-magic">dispel magic</a>, except that the
maximum caster level on your dispel check is +20 instead of +10.</p>
    <p>Additionally, greater dispel magic has a chance to dispel any
effect that remove curse can remove, even if dispel magic can&rsquo;t
dispel
that effect.</p>
    <h6><a id="displacement">Displacement</a></h6>
    <p className="initial"><i>Illusion (Glamer)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>: 1
round/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>The subject of this spell appears to be about 2 feet away from
its true location. The creature benefits from a 50% miss chance as if
it had total concealment. However, unlike actual total concealment,
displacement does not prevent enemies from targeting the creature
normally. True seeing reveals its true location.</p>
    <p><i>Material Component</i>: A small strip of leather twisted
into a loop.</p>
    <h6><a id="disrupt-undead">Disrupt Undead</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 0</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>: Ray</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You direct a ray of positive energy. You must make a ranged
touch attack to hit, and if the ray hits an undead creature, it deals
1d6 points of damage to it.</p>
    <h6><a id="disrupting-weapon">Disrupting Weapon</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 5</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Targets</b>:
One melee weapon</span> <span className="stat-block"><b>Duration</b>: 1
round/level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(harmless, object); see text</span> <span className="stat-block"><b>Spell
Resistance</b>: Yes (harmless,
object)</span>
    <p>This spell makes a melee weapon deadly to undead. Any undead
creature with HD equal to or less than your caster level must succeed
on a Will save or be destroyed utterly if struck in combat with this
weapon. Spell resistance does not apply against the destruction effect.</p>
    <h6><a id="divination">Divination</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Clr 4, Knowledge 4</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
Instantaneous </span>
    <p>Similar to augury but more powerful, a divination spell can
provide you with a useful piece of advice in reply to a question
concerning a specific goal, event, or activity that is to occur within
one week. The advice can be as simple as a short phrase, or it might
take the form of a cryptic rhyme or omen. If your party doesn&rsquo;t
act on
the information, the conditions may change so that the information is
no longer useful. The base chance for a correct divination is 70% + 1%
per caster level, to a maximum of 90%. If the dice roll fails, you know
the spell failed, unless specific magic yielding false information is
at work.</p>
    <p>As with augury, multiple divinations about the same topic by
the same caster use the same dice result as the first divination spell
and yield the same answer each time.</p>
    <p><i>Material Component</i>: Incense and a sacrificial offering
appropriate to your religion, together worth at least 25 gp.</p>
    <h6><a id="divine-favor">Divine Favor</a></h6>
    <p className="initial"><i>Evocation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1, Pal 1</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 minute</span>
    <p>Calling upon the strength and wisdom of a deity, you gain a +1
luck bonus on attack and weapon damage rolls for every three caster
levels you have (at least +1, maximum +3). The bonus doesn&rsquo;t
apply to
spell damage.</p>
    <h6><a id="divine-power">Divine Power</a></h6>
    <p className="initial"><i>Evocation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 4, War 4</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 round/level</span>
    <p>Calling upon the divine power of your patron, you imbue
yourself with strength and skill in combat. Your base attack bonus
becomes equal to your character level (which may give you additional
attacks), you gain a +6 enhancement bonus to Strength, and you gain 1
temporary hit point per caster level.</p>
    <h6><a id="dominate-animal">Dominate Animal</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Animal 3, Drd 3</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 round</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One animal</span>
    <span className="stat-block"><b>Duration</b>: 1 round/level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You can enchant an animal and direct it with simple commands
such as &ldquo;Attack,&rdquo; &ldquo;Run,&rdquo; and
&ldquo;Fetch.&rdquo; Suicidal or self-destructive
commands (including an order to attack a creature two or more size
categories larger than the dominated animal) are simply ignored.</p>
    <p>Dominate animal establishes a mental link between you and the
subject creature. The animal can be directed by silent mental command
as long as it remains in range. You need not see the creature to
control it. You do not receive direct sensory input from the creature,
but you know what it is experiencing. Because you are directing the
animal with your own intelligence, it may be able to undertake actions
normally beyond its own comprehension. You need not concentrate
exclusively on controlling the creature unless you are trying to direct
it to do something it normally couldn&rsquo;t do. Changing your
instructions
or giving a dominated creature a new command is the equivalent of
redirecting a spell, so it is a move action.</p>
    <h6><a id="dominate-monster">Dominate Monster</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 9</span> <span className="stat-block"><b>Target</b>: One creature</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#dominate-person">dominate person</a>, except that the
spell is not restricted by creature type.</p>
    <h6><a id="dominate-person">Dominate Person</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 4, Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 round</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One humanoid</span>
    <span className="stat-block"><b>Duration</b>: One day/level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You can control the actions of any humanoid creature through a
telepathic link that you establish with the subject&rsquo;s mind.</p>
    <p>If you and the subject have a common language, you can
generally force the subject to perform as you desire, within the limits
of its abilities. If no common language exists, you can communicate
only basic commands, such as &ldquo;Come here,&rdquo; &ldquo;Go
there,&rdquo; &ldquo;Fight,&rdquo; and
&ldquo;Stand still.&rdquo; You know what the subject is experiencing,
but you do
not receive direct sensory input from it, nor can it communicate with
you telepathically.</p>
    <p>Once you have given a dominated creature a command, it
continues to attempt to carry out that command to the exclusion of all
other activities except those necessary for day-to-day survival (such
as sleeping, eating, and so forth). Because of this limited range of
activity, a <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense Motive</a> check against DC 15
(rather than DC 25) can
determine that the subject&rsquo;s behavior is being influenced by an
enchantment effect (see the Sense Motive skill description).</p>
    <p>Changing your instructions or giving a dominated creature a
new command is the equivalent of redirecting a spell, so it is a move
action.</p>
    <p>By concentrating fully on the spell (a standard action), you
can receive full sensory input as interpreted by the mind of the
subject, though it still can&rsquo;t communicate with you. You
can&rsquo;t actually
see through the subject&rsquo;s eyes, so it&rsquo;s not as good as
being there
yourself, but you still get a good idea of what&rsquo;s going on.</p>
    <p>Subjects resist this control, and any subject forced to take
actions against its nature receives a new saving throw with a +2 bonus.
Obviously self-destructive orders are not carried out. Once control is
established, the range at which it can be exercised is unlimited, as
long as you and the subject are on the same plane. You need not see the
subject to control it.</p>
    <p>If you don&rsquo;t spend at least 1 round concentrating on the
spell
each day, the subject receives a new saving throw to throw off the
domination.</p>
    <p>Protection from evil or a similar spell can prevent you from
exercising control or using the telepathic link while the subject is so
warded, but such an effect neither prevents the establishment of
domination nor dispels it.</p>
    <h6><a id="doom">Doom</a></h6>
    <p className="initial"><i>Necromancy [Fear, Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Target</b>: One living
creature</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span>
    <span className="stat-block"><b>Saving Throw</b>: Will negates</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell fills a single subject with a feeling of horrible
dread that causes it to become <a href="abilitiesAndConditions.html#shaken" style={{
        "color": "rgb(87, 158, 182)"
      }}>shaken</a>.</p>
    <h6><a id="dream">Dream</a></h6>
    <p className="initial"><i>Illusion (Phantasm) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 5, Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 minute</span> <span className="stat-block"><b>Range</b>: Unlimited</span> <span className="stat-block"><b>Target</b>: One living creature
touched</span> <span className="stat-block"><b>Duration</b>: See text</span>
    <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You, or a messenger touched by you, sends a phantasmal message
to others in the form of a dream. At the beginning of the spell, you
must name the recipient or identify him or her by some title that
leaves no doubt as to identity. The messenger then enters a trance,
appears in the intended recipient&rsquo;s dream, and delivers the
message.
The message can be of any length, and the recipient remembers it
perfectly upon waking. The communication is one-way. The recipient
cannot ask questions or offer information, nor can the messenger gain
any information by observing the dreams of the recipient.</p>
    <p>Once the message is delivered, the messenger&rsquo;s mind
returns
instantly to its body. The duration of the spell is the time required
for the messenger to enter the recipient&rsquo;s dream and deliver the
message.</p>
    <p>If the recipient is awake when the spell begins, the messenger
can choose to wake up (ending the spell) or remain in the trance. The
messenger can remain in the trance until the recipient goes to sleep,
then enter the recipient&rsquo;s dream and deliver the message as
normal. A
messenger that is disturbed during the trance comes awake, ending the
spell.</p>
    <p>Creatures who don&rsquo;t sleep (such as elves, but not
half-elves)
or don&rsquo;t dream cannot be contacted by this spell.</p>
    <p>The messenger is unaware of its own surroundings or of the
activities around it while in the trance. It is defenseless both
physically and mentally (always fails any saving throw) while in the
trance.</p>
    <h6><a id="eagles-splendor">Eagle&rsquo;s Splendor</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Clr 2, Pal 2,
Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>: Creature touched</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>The transmuted creature becomes more poised, articulate, and
personally forceful. The spell grants a +4 enhancement bonus to
Charisma, adding the usual benefits to Charisma-based skill checks and
other uses of the Charisma modifier. Sorcerers and bards (and other
spellcasters who rely on Charisma) affected by this spell do not gain
any additional bonus spells for the increased Charisma, but the save
DCs for spells they cast while under this spell&rsquo;s effect do
increase.</p>
    <p><i>Arcane Material Component</i>: A few feathers or a pinch of
droppings from an eagle.</p>
    <h6><a id="mass-eagles-splendor">Eagle&rsquo;s Splendor, Mass</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 6, Clr 6, Sor/Wiz 6</span>
    <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One
creature/level, no
two of which can be more than 30 ft. apart</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#eagles-splendor">eagle&rsquo;s splendor</a>, except that
it
affects multiple creatures.</p>
    <h6><a id="earthquake">Earthquake</a></h6>
    <p className="initial"><i>Evocation [Earth]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 8, Destruction 8, Drd
8, Earth 7</span> <span className="stat-block"><b>Components</b>: V, S, DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Area</b>: 80-ft.-radius
spread (S)</span> <span className="stat-block"><b>Duration</b>: 1 round</span>
    <span className="stat-block"><b>Saving Throw</b>: See text</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>When you cast earthquake, an intense but highly localized
tremor rips the ground. The shock knocks creatures down, collapses
structures, opens cracks in the ground, and more. The effect lasts for
1 round, during which time creatures on the ground can&rsquo;t move or
attack. A spellcaster on the ground must make a <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a>
check (DC 20 + spell level) or lose any spell he or she tries to cast.
The earthquake affects all terrain, vegetation, structures, and
creatures in the area. The specific effect of an earthquake spell
depends on the nature of the terrain where it is cast.</p>
    <p><i>Cave, Cavern, or Tunnel</i>: The spell collapses the roof,
dealing 8d6 points of bludgeoning damage to any creature caught under
the cave-in (Reflex DC 15 half ) and pinning that creature beneath the
rubble (see below). An earthquake cast on the roof of a very large
cavern could also endanger those outside the actual area but below the
falling debris.</p>
    <p><i>Cliffs</i>: Earthquake causes a cliff to crumble, creating
a landslide that travels horizontally as far as it fell vertically. Any
creature in the path takes 8d6 points of bludgeoning damage (Reflex DC
15 half ) and is pinned beneath the rubble (see below).</p>
    <p><i>Open Ground</i>: Each creature standing in the area must
make a DC 15 Reflex save or fall down. Fissures open in the earth, and
every creature on the ground has a 25% chance to fall into one (Reflex
DC 20 to avoid a fissure). At the end of the spell, all fissures grind
shut, killing any creatures still trapped within.</p>
    <p><i>Structure</i>: Any structure standing on open ground takes
100 points of damage, enough to collapse a typical wooden or masonry
building, but not a structure built of stone or reinforced masonry.
Hardness does not reduce this damage, nor is it halved as damage dealt
to objects normally is. Any creature caught inside a collapsing
structure takes 8d6 points of bludgeoning damage (Reflex DC 15 half )
and is pinned beneath the rubble (see below).</p>
    <p><i>River, Lake, or Marsh</i>: Fissures open underneath the
water, draining away the water from that area and forming muddy ground.
Soggy marsh or swampland becomes quicksand for the duration of the
spell, sucking down creatures and structures. Each creature in the area
must make a DC 15 Reflex save or sink down in the mud and quicksand. At
the end of the spell, the rest of the body of water rushes in to
replace the drained water, possibly drowning those caught in the mud.</p>
    <p><i>Pinned beneath Rubble</i>: Any creature pinned beneath
rubble takes 1d6 points of nonlethal damage per minute while pinned. If
a pinned character falls <a href="abilitiesAndConditions.html#unconscious" style={{
        "color": "rgb(87, 158, 182)"
      }}>unconscious</a>, he or she must make
a DC 15
Constitution check or take 1d6 points of lethal damage each minute
thereafter until freed or <a href="abilitiesAndConditions.html#dead" style={{
        "color": "rgb(71, 158, 182)"
      }}>dead</a>.</p>
    <h6><a id="elemental-swarm">Elemental Swarm</a></h6>
    <p className="initial"><i>Conjuration (Summoning) [see text]</i></p>
    <span className="stat-block"><b>Level</b>: Air 9, Drd 9, Earth 9,
Fire 9, Water 9</span> <span className="stat-block"><b>Components</b>: V, S</span>
    <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Two or more
summoned
creatures, no two of which can be more than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: 10 min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell opens a portal to an Elemental Plane and summons
elementals from it. A druid can choose the plane (Air, Earth, Fire, or
Water); a cleric opens a portal to the plane matching his domain.</p>
    <p>When the spell is complete, 2d4 Large elementals appear. Ten
minutes later, 1d4 Huge elementals appear. Ten minutes after that, one
greater elemental appears. Each elemental has maximum hit points per
HD. Once these creatures appear, they serve you for the duration of the
spell.</p>
    <p>The elementals obey you explicitly and never attack you, even
if someone else manages to gain control over them. You do not need to
concentrate to maintain control over the elementals. You can dismiss
them singly or in groups at any time.</p>
    <p>When you use a summoning spell to summon an air, earth, fire,
or water creature, it is a spell of that type.</p>
    <h6><a id="endure-elements">Endure Elements</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1, Drd 1, Pal 1, Rgr
1, Sor/Wiz 1, Sun 1</span> <span className="stat-block"><b>Components</b>:
V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard
action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>: Creature touched</span> <span className="stat-block"><b>Duration</b>: 24 hours</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>A creature protected by endure elements suffers no harm from
being in a hot or cold environment. It can exist comfortably in
conditions between &ndash;50 and 140 degrees Fahrenheit without having
to
make Fortitude saves). The creature&rsquo;s equipment is likewise
protected.</p>
    <p>Endure elements doesn&rsquo;t provide any protection from fire
or
cold damage, nor does it protect against other environmental hazards
such as smoke, lack of air, and so forth.</p>
    <h6><a id="energy-drain">Energy Drain</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 9, Sor/Wiz 9</span> <span className="stat-block"><b>Saving Throw</b>: Fortitude partial;
see text for enervation</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#enervation">enervation</a>, except that the creature
struck gains 2d4 <a href="abilitiesAndConditions.html#energy-drained" style={{
        "color": "rgb(87, 158, 182)"
      }}>negative levels</a>, and the
negative levels last longer.</p>
    <p>There is no saving throw to avoid gaining the negative levels,
but 24 hours after gaining them, the subject must make a Fortitude
saving throw (DC = energy drain spell&rsquo;s save DC) for each
negative
level. If the save succeeds, that negative level is removed. If it
fails, the negative level also goes away, but one of the
subject&rsquo;s
character levels is permanently drained.</p>
    <p>An undead creature struck by the ray gains 2d4x5 temporary hit
points for 1 hour.</p>
    <h6><a id="enervation">Enervation</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>: Ray of negative
energy</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span>
    <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You point your finger and utter the incantation, releasing a
black ray of crackling negative energy that suppresses the life force
of any living creature it strikes. You must make a ranged touch attack
to hit. If the attack succeeds, the subject gains 1d4 negative levels.</p>
    <p>If the subject has at least as many negative levels as HD, it
dies. Each negative level gives a creature a &ndash;1 penalty on attack
rolls, saving throws, skill checks, ability checks, and effective level
(for determining the power, duration, DC, and other details of spells
or special abilities).</p>
    <p>Additionally, a spellcaster loses one spell or spell slot from
his or her highest available level. Negative levels stack.</p>
    <p>Assuming the subject survives, it regains lost levels after a
number of hours equal to your caster level (maximum 15 hours). Usually,
negative levels have a chance of permanently <a href="abilitiesAndConditions.html#energy-drained" style={{
        "color": "rgb(87, 158, 182)"
      }}>draining</a> the victim&rsquo;s
levels, but the negative levels from enervation don&rsquo;t last long
enough
to do so.</p>
    <p>An undead creature struck by the ray gains 1d4x5 temporary hit
points for 1 hour.</p>
    <h6><a id="enlarge-person">Enlarge Person</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 1, Strength 1</span>
    <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 round</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One humanoid
creature</span> <span className="stat-block"><b>Duration</b>: 1 min./level
(D)</span> <span className="stat-block"><b>Saving Throw</b>: Fortitude
negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell causes instant growth of a humanoid creature,
doubling its height and multiplying its weight by 8. This increase
changes the creature&rsquo;s size category to the next larger one. The
target
gains a +2 size bonus to Strength, a &ndash;2 size penalty to Dexterity
(to a
minimum of 1), and a &ndash;1 penalty on attack rolls and AC due to its
increased size.</p>
    <p>A humanoid creature whose size increases to Large has a space
of 10 feet and a natural reach of 10 feet. This spell does not change
the target&rsquo;s speed.</p>
    <p>If insufficient room is available for the desired growth, the
creature attains the maximum possible size and may make a Strength
check (using its increased Strength) to burst any enclosures in the
process. If it fails, it is constrained without harm by the materials
enclosing it&mdash; the spell cannot be used to crush a creature by
increasing its size.</p>
    <p>All equipment worn or carried by a creature is similarly
enlarged by the spell. Melee and projectile weapons affected by this
spell deal more damage. Other magical properties are not affected by
this spell. Any enlarged item that leaves an enlarged creature&rsquo;s
possession (including a projectile or thrown weapon) instantly returns
to its normal size. This means that thrown weapons deal their normal
damage, and projectiles deal damage based on the size of the weapon
that fired them. Magical properties of enlarged items are not increased
by this spell.</p>
    <p>Multiple magical effects that increase size do not stack,.</p>
    <p>Enlarge person counters and dispels reduce person.</p>
    <p>Enlarge person can be made permanent with a permanency spell.</p>
    <p><i>Material Component</i>: A pinch of powdered iron.</p>
    <h6><a id="mass-enlarge-person">Enlarge Person, Mass</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 4</span> <span className="stat-block"><b>Target</b>: One humanoid
creature/level, no two of which can be more than 30 ft. apart</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#enlarge-person">enlarge person</a>, except that it
affects multiple creatures.</p>
    <h6><a id="entangle">Entangle</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 1, Plant 1, Rgr 1</span>
    <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Area</b>: Plants in a
40-ft.-radius
spread</span> <span className="stat-block"><b>Duration</b>: 1 min./level
(D)</span> <span className="stat-block"><b>Saving Throw</b>: Reflex
partial; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>Grasses, weeds, bushes, and even trees wrap, twist, and
entwine about creatures in the area or those that enter the area,
holding them fast and causing them to become <a href="abilitiesAndConditions.html#entangled" style={{
        "color": "rgb(87, 158, 182)"
      }}>entangled</a>. The creature
can break free and move half its normal speed by using a full-round
action to make a DC 20 Strength check or a DC 20 <a href="skillsAll.html#escape-artist" style={{
        "color": "rgb(87, 158, 182)"
      }}>Escape
Artist</a> check. A
creature that succeeds on a Reflex save is not entangled but can still
move at only half speed through the area. Each round on your turn, the
plants once again attempt to entangle all creatures that have avoided
or escaped entanglement.</p>
    <p><i>Note</i>: The effects of the spell may be altered somewhat,
based on the nature of the entangling plants.</p>
    <h6><a id="enthrall">Enthrall</a></h6>
    <p className="initial"><i>Enchantment (Charm) [Language Dependent,
Mind-Affecting, Sonic]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Clr 2</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 round</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Targets</b>: Any number
of creatures</span> <span className="stat-block"><b>Duration</b>: 1 hour
or less</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>If you have the attention of a group of creatures, you can use
this spell to hold them spellbound. To cast the spell, you must speak
or sing without interruption for 1 full round. Thereafter, those
affected give you their undivided attention, ignoring their
surroundings. They are considered to have an attitude of friendly while
under the effect of the spell. Any potentially affected creature of a
race or religion unfriendly to yours gets a +4 bonus on the saving
throw.</p>
    <p>A creature with 4 or more HD or with a Wisdom score of 16 or
higher remains aware of its surroundings and has an attitude of
indifferent. It gains a new saving throw if it witnesses actions that
it opposes.</p>
    <p>The effect lasts as long as you speak or sing, to a maximum of
1 hour. Those enthralled by your words take no action while you speak
or sing and for 1d3 rounds thereafter while they discuss the topic or
performance. Those entering the area during the performance must also
successfully save or become enthralled. The speech ends (but the
1d3-round delay still applies) if you lose concentration or do anything
other than speak or sing.</p>
    <p>If those not enthralled have unfriendly or hostile attitudes
toward you, they can collectively make a Charisma check to try to end
the spell by jeering and heckling. For this check, use the Charisma
bonus of the creature with the highest Charisma in the group; others
may make Charisma checks to assist. The heckling ends the spell if this
check result beats your Charisma check result. Only one such challenge
is allowed per use of the spell.</p>
    <p>If any member of the audience is attacked or subjected to some
other overtly hostile act, the spell ends and the previously enthralled
members become immediately unfriendly toward you. Each creature with 4
or more HD or with a Wisdom score of 16 or higher becomes hostile.</p>
    <h6><a id="entropic-shield">Entropic Shield</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1, Luck 1</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 min./level (D)</span>
    <p>A magical field appears around you, glowing with a chaotic
blast of multicolored hues. This field deflects incoming arrows, rays,
and other ranged attacks. Each ranged attack directed at you for which
the attacker must make an attack roll has a 20% miss chance (similar to
the effects of concealment). Other attacks that simply work at a
distance are not affected.</p>
    <h6><a id="erase">Erase</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One scroll or
two pages</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: See
text</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>Erase removes writings of either magical or mundane nature
from a scroll or from one or two pages of paper, parchment, or similar
surfaces. With this spell, you can remove explosive runes, a glyph of
warding, a sepia snake sigil, or an arcane mark, but not illusory
script or a symbol spell. Nonmagical writing is automatically erased if
you touch it and no one else is holding it. Otherwise, the chance of
erasing nonmagical writing is 90%.</p>
    <p>Magic writing must be touched to be erased, and you also must
succeed on a caster level check (1d20 + caster level) against DC 15. (A
natural 1 or 2 is always a failure on this check.) If you fail to erase
explosive runes, a glyph of warding, or a sepia snake sigil, you
accidentally activate that writing instead.</p>
    <h6><a id="ethereal-jaunt">Ethereal Jaunt</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 7, Sor/Wiz 7</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 round/level (D)</span>
    <p>You become ethereal, along with your equipment. For the
duration of the spell, you are in a place called the Ethereal Plane,
which overlaps the normal, physical, Material Plane. When the spell
expires, you return to material existence.</p>
    <p>An ethereal creature is <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a>, insubstantial, and
capable
of moving in any direction, even up or down, albeit at half normal
speed. As an insubstantial creature, you can move through solid
objects, including living creatures. An ethereal creature can see and
hear on the Material Plane, but everything looks gray and ephemeral.
Sight and hearing onto the Material Plane are limited to 60 feet.</p>
    <p>Force effects and abjurations affect an ethereal creature
normally. Their effects extend onto the Ethereal Plane from the
Material Plane, but not vice versa. An ethereal creature can&rsquo;t
attack
material creatures, and spells you cast while ethereal affect only
other ethereal things. Certain material creatures or objects have
attacks or effects that work on the Ethereal Plane.</p>
    <p>Treat other ethereal creatures and ethereal objects as if they
were material. </p>
    <p>If you end the spell and become material while inside a
material object (such as a solid wall), you are shunted off to the
nearest open space and take 1d6 points of damage per 5 feet that you so
travel.</p>
    <h6><a id="etherealness">Etherealness</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 9, Sor/Wiz 9</span> <span className="stat-block"><b>Range</b>: Touch; see text</span> <span className="stat-block"><b>Targets</b>: You and one other
touched creature per three levels</span> <span className="stat-block"><b>Duration</b>:
1 min./level (D)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#ethereal-jaunt">ethereal jaunt</a>, except that you and
other willing creatures joined by linked hands (along with their
equipment) become ethereal. Besides yourself, you can bring one
creature per three caster levels to the Ethereal Plane. Once ethereal,
the subjects need not stay together.</p>
    <p>When the spell expires, all affected creatures on the Ethereal
Plane return to material existence.</p>
    <h6><a id="expeditious-retreat">Expeditious Retreat</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 min./level (D)</span>
    <p>This spell increases your base land speed by 30 feet. (This
adjustment is treated as an enhancement bonus.) There is no effect on
other modes of movement, such as burrow, climb, fly, or swim. As with
any effect that increases your speed, this spell affects your jumping
distance (see the Jump skill).</p>
    <h6><a id="explosive-runes">Explosive Runes</a></h6>
    <p className="initial"><i>Abjuration [Force]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
One touched object
weighing no more than 10 lb.</span> <span className="stat-block"><b>Duration</b>:
Permanent until
discharged (D)</span> <span className="stat-block"><b>Saving Throw</b>:
See text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You trace these mystic runes upon a book, map, scroll, or
similar object bearing written information. The runes detonate when
read, dealing 6d6 points of force damage. Anyone next to the runes
(close enough to read them) takes the full damage with no saving throw;
any other creature within 10 feet of the runes is entitled to a Reflex
save for half damage. The object on which the runes were written also
takes full damage (no saving throw).</p>
    <p>You and any characters you specifically instruct can read the
protected writing without triggering the runes. Likewise, you can
remove the runes whenever desired. Another creature can remove them
with a successful dispel magic or erase spell, but attempting to dispel
or erase the runes and failing to do so triggers the explosion.</p>
    <p><i>Note</i>: Magic traps such as explosive runes are hard to
detect and disable. A rogue (only) can use the <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a>
skill to find the
runes and <a href="skillsAll.html#disable-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disable Device</a> to thwart them.
The DC in each case is 25 +
spell level, or 28 for explosive runes.</p>
    <h6><a id="eyebite">Eyebite</a></h6>
    <p className="initial"><i>Necromancy [Evil]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 6, Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One living
creature</span> <span className="stat-block"><b>Duration</b>: 1 round per
three
levels; see text</span> <span className="stat-block"><b>Saving Throw</b>:
Fortitude negates</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>Each round, you may target a single living creature, striking
it with waves of evil power. Depending on the target&rsquo;s HD, this
attack
has as many as three effects.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>HD</th>
          <th style={{
            "width": "200px"
          }}>Effect</th>
        </tr>
        <tr className="odd-row">
          <td>10 or more</td>
          <td><a href="abilitiesAndConditions.html#sickened" style={{
              "color": "rgb(87, 158, 182)"
            }}>Sickened</a></td>
        </tr>
        <tr>
          <td>5&ndash;9</td>
          <td><a href="abilitiesAndConditions.html#panicked" style={{
              "color": "rgb(87, 158, 182)"
            }}>Panicked</a>, sickened</td>
        </tr>
        <tr className="odd-row">
          <td>4 or less</td>
          <td>Comatose, panicked, sickened</td>
        </tr>
      </tbody>
    </table>
    <p>The effects are cumulative and concurrent.</p>
    <p><i>Sickened</i>: Sudden pain and fever sweeps over the
subject&rsquo;s body. A sickened creature takes a &ndash;2 penalty on
attack rolls,
weapon damage rolls, saving throws, skill checks, and ability checks. A
creature affected by this spell remains sickened for 10 minutes per
caster level. The effects cannot be negated by a remove disease or heal
spell, but a remove curse is effective.</p>
    <p><i>Panicked</i>: The subject becomes panicked for 1d4 rounds.
Even after the panic ends, the creature remains <a href="abilitiesAndConditions.html#shaken" style={{
        "color": "rgb(87, 158, 182)"
      }}>shaken</a> for 10 minutes
per caster level, and it automatically becomes panicked again if it
comes within sight of you during that time. This is a fear effect.</p>
    <p><i>Comatose</i>: The subject falls into a catatonic coma for
10 minutes per caster level. During this time, it cannot be awakened by
any means short of dispelling the effect. This is not a sleep effect,
and thus elves are not immune to it.</p>
    <p>The spell lasts for 1 round per three caster levels. You must
spend a move action each round after the first to target a foe.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      